/* PaymentForm.css */
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  @keyframes zoomIn {
    0% { transform: scale(0.5); }
    100% { transform: scale(1); }
  }
  
  .icon-spin {
    animation: spin 2s linear infinite;
  }
  
  .icon-zoom {
    animation: zoomIn 0.5s forwards;
  }

  
.payment-form {
    width: 100%;
    max-width: 650px;
    margin: 50px auto;
    padding: 20px;
    background: rgba(86, 143, 209, 0.25);
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    
}

.payment-form h1 {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
    color: goldenrod;
}



.payment-form input:focus {
    border-color: #007BFF;
}

.payment-form label {
    color: white;
    align-items: center;

    
}


/* Styling for the placeholders */
.payment-form input::placeholder {
    font-family: Montserrat, sans-serif;
    font-weight: 400;
    color: #333;
    text-shadow: 2px 2px 4px rgba(0,0,0,0.4);
}

.payment-form input:required {
    border-color: #FF0000; /* Red border for required fields */
}


.paymentButtons{
    display: flex;
    width: 100%;
    text-align: center;
    margin-right: 10px;
    overflow-x: auto;    /* Make it horizontally scrollable when needed */
    white-space: nowrap;
}
.btn-Choice {
  flex: none;          /* Prevent buttons from growing or shrinking */
  margin-right: 10px;  /* Space between buttons */
  /* Add any other styles for your buttons here */
}
.payment-form button {
    width: 45%;
    margin: 5px;
    margin-top: 20px;
    border-radius: 14px;
    height: 44px;
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
    -webkit-transition : all 200ms linear;
    transition: all 200ms linear;
    padding: 0 30px;
    letter-spacing: 1px;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    text-align: center;
    border: none;
    background-color: goldenrod;
    color: #102770;
    box-shadow: 0 8px 24px 0 rgba(255,235,167,.2);
}

.payment-form button:active,
.payment-form button:focus{  
  background-color: #102770;
  color: #ffeba7;
  box-shadow: 0 8px 24px 0 rgba(16,39,112,.2);
}
.payment-form button:hover{  
  background-color: #102770;
  color: #ffeba7;
  box-shadow: 0 8px 24px 0 rgba(16,39,112,.2);
}



.payment-form button:hover {
    background-color: #030609;
}




p{
    font-weight: 500;
    color: white;
    opacity: 0.7;
    font-size: 1.1rem;
    margin-top: 0;
    margin-bottom: 60px;
    text-shadow: 2px 2px 4px rgba(255, 255, 255, 0.2);
}



  
  .input-container {
    height: 50px;
    position: relative;
    width: 100%;
    
  }
 
  
  .ic1 {
    margin-top: 40px;
  }
  
  .ic2 {
    margin-top: 30px;
  }
 
  .react-tel-input .form-control, .payment-form input {
    background-color: black;
    border-radius: 12px;
    border: 0;
    box-sizing: border-box;
    color: #eee;
    font-size: 18px;
    height: 55px;
    outline: 0;
    padding: 4px 20px 0;
    width: 100%;

   


  }
  
 .payment-form input[type="tel"]  {
    color: white;
    margin-left: 20px;
    width: 90%;
 }
  
  .inputPhone{
    display: flex;
    
   
  }
  .inputPhone label{
    color: white;
    margin-left: 60px;

  }
  .inputPhone label:active{
    margin-left: 10px;

  }
  .inputPhone .input-container label .placeholder{
    margin-left: 10px;
    color: yellow;

  }
  .inputPhone .input-container :focus ~  .cut .placeholder,
  .inputPhone .input-container :not(:placeholder-shown) ~   .cut .placeholder{
  text-align: start;
  display: flex;
  color: wheat  ;
}
 
  .react-tel-input .form-control{
    width: 50px;
    background-color: transparent;
  }
  .input-container input[type="tel"] value{
    margin-right: 10px;
  }
 

  .input-container select {
    height: 55px;
    width: 100%;
    border-radius: 14px;
    font-size: 17px;
    font-weight: 600;
    padding: 10px;
    background-color: black;
    color: rgb(255, 255, 255);
    border: none;
    -webkit-appearance: none;  /* for Chrome, Safari, Opera */
    -moz-appearance: none;     /* for Firefox */
    appearance: none;   
    margin-bottom: 10px;

    
}


  .cut {
    background-color: #15172b;
    border-radius: 10px;
    height: 20px;
    left: 20px;
    position: absolute;
    top: -20px;
    transform: translateY(0);
    transition: transform 200ms;
    width: 130px;
    justify-content: center;
    display: none;
    color: rgb(7, 148, 23);

  }
  
  
 
  .payment-form input:focus ~  .cut,
  .payment-form input:not(:placeholder-shown) ~   .cut {
    transform: translateY(8px);
    display: block;

    
  }
 
  .placeholder {
    color: #777b65;
    font-family: sans-serif;
    left: 20px;
    line-height: 14px;
    pointer-events: none;
    position: absolute;
    transform-origin: 0 50%;
    transition: transform 200ms, color 200ms;
    top: 20px;
    

  }
  .placeholder input[type="tel"]::before    {
    display: none;
    color: rgb(47, 124, 40);
    background-color: rgb(11, 11, 1);
  }
  
  .payment-form input:focus ~ .placeholder,
  .payment-form input:not(:placeholder-shown) ~ .placeholder {
    transform: translateY(-30px) translateX(10px) scale(0.75);

}
  
  .payment-form input:not(:placeholder-shown) ~ .placeholder {
    color: #ff0202;
    

  }
  
  .payment-form input:focus ~ .placeholder {
    color: #dc2f55;
    
  }
  .submit{
    background-color: red;
  }
  
 
  /* PaymentForm.css */

.success {
    align-items: center;
    padding: 10px 20px;
    background-color: #4CAF50; /* green background color */
    color: #ffffff; /* white text color */
    border: 1px solid #388E3C; /* green border */
    z-index: 1000; /* high z-index to ensure it's on top of other elements */
    border-radius: 20px;
}
.btn-submit button{
    background-color: rgb(27, 8, 94);
    color: white;
}
.btn-submit button:hover{
    background-color: rgb(54, 214, 232);
    color: #000102;
    

}
.btn-submit button:active,
.btn-submit button:focus{  
  background-color: purple;
  color: #ffeba7;
  box-shadow: 0 8px 24px 0 rgba(16,39,112,.2);
}

.icon{
    width: 20px;
    height: 20px;
    align-items: center;
    border-right: 1px solid white;
    margin-right: 6px;
}

@media (max-width: 478px) {
  .paymentButtons{
   width: 95%;
   border-radius: 20px;
   height: 90px;
   margin: 10px;

   box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px;  }
  .payment-form button {
    width: 170px;
    margin-left: 0;
  }
}
@media (max-width: 767px) {
    .payment-form {
        width: 94%;
        padding: 10px;
        font-size: 14px;
        align-items: center;
    }
  
   
    .btn-submit button{
        display: flex;
        width: 300px;
        border-radius: 14px;
        text-transform: inherit;
        font-size: 10px;
        margin: 4px;
        padding: 5px;

    }
}
/* Customize the overall container */
.react-tel-input {
    width: 100px;
    margin-bottom: 20px;
    background-color: transparent;
    color: white;
    margin-top: 20px;
    border-radius: 10px;

}
.react-tel-input:hover {
    cursor:default;
}

/* Customize the input field */

/* Customize the country dropdown button */
.react-tel-input .flag-dropdown {
    border-left: 1px solid #ccc;
    margin: 10px;
    width: 95%;
    font-size: 11px;
    color: white;
    background-color: black;
    border-radius: 15px;
    margin-top: 10px;
    margin-left: 5px;
    margin-bottom: 10px;
    height: 55px;
    border: none;

}



/* Hover effect on dropdown button */
.react-tel-input .country:hover{
    background-color: transparent;
}

/* Custom styles for the dropdown list */
.react-tel-input .country-list {
    border: 1px solid #ccc;
    max-height: 300px;
    overflow-y: auto;
    width: 100%;
    background: rgba( 255, 255, 255, 0.25 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    color: rgb(255, 255, 255);
    align-items: flex-start;
    margin: 4px;
    margin-left: 0;
    padding-top: 10px;
    scroll-margin: none;
}
.react-tel-input .country-list:hover {
    color: white;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(21, 3, 3, 0.3);
    width: 150px;
    background-color: transparent;
    margin: 10px;
    
}

/* Highlight for the active/selected country */
.react-tel-input .country.active {
    background-color: transparent;
    align-items: flex-start;
    background-color: rgb(45, 45, 56);
    margin-top: 5px;
    border-radius: 20px;
    text-align: center;
    color: white;
    margin-right: 10px;
    
    


}
.react-tel-input .country.active:hover {
    background-color: rgb(0, 0, 5);
    transition: 0.6s;
    color: white;
}

/* Hover effect for countries in dropdown list */
.react-tel-input .country:hover {
    color: black;
    border-radius: 13px;
    margin: 3px;
    margin-left: 0;
    
}

.qrImage img{
    border-radius: 13px;
    width: 300px;
    height: 500px;
}

.walletAddress{
  margin-top: 20px;
  font-weight: lighter;
  font-size: 14px;
  color: white;
  font-family: 'Courier New', Courier, monospace;
}

.blur-border {
  margin: 40px;
  color: white;
  position: relative;
  /* Additional styles for the div */
  padding-left: 4px; /* To ensure content doesn't overlap with the blurred border */
}

.blur-border::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
 
  width: 8px;
  margin-top: -200px;
  height: 200px;
  background: currentColor; /* Assumes the border color is the same as the text color, adjust as necessary */
  filter: blur(4px); /* Adjust the value for more/less blur */
  transform: scaleX(1.5); /* You might need this to compensate for blur bleeding */
  z-index: -1; /* Put it behind the content */
}
